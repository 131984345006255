/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SiteLinks from "../components/site-links"
import { Separator } from "../components/gatsby-sidebar/docs-breadcrumb"

const ThirdPage = ({ location }) => (
  <Layout>
    <SEO title="Project - Valencia" />

    <div
      sx={{
        m: `1.45rem auto`,
        maxWidth: 900,
      }}
    >
        <div className="header">
        <img src="images/TSK_Solutions_Logo.bmp" alt="company logo" id="companylogo" className="img-float-right" width="200" />

        </div>

        <div className="section">
            <div className="section-header">
                <h1>Welcome</h1>
            </div>

            <img src="../../images/DJH_profile_pic.jpg" alt="Me" id="mypic" className="img-float-left" width="20%" />
            {/* 
                <img src="images/TSK_Solutions_Logo.bmp" alt="company logo" id="companylogo" class="img-float-right" width="200" /> 
            */}        

            <p id="firstp">
                Hello!  I am Dan J. Hamilton.<br /><br />
                
                After a 27-year career as an actuary, I am trying to decide what to do as an encore.<br /><br />
                Currently, I am attending Valencia College pursuing a Technical Certificate in Computer Programming.<br /><br />
                <br /><br />
            </p>
        </div>

        <div className="footer">
            <div className="address">
                483 Cascading Creek Lane &nbsp;&#149;&nbsp;
                Winter Garden, FL &nbsp;34787
            </div>
        </div>
    </div>


    <SiteLinks>
      <Link to="/">Landing Page</Link>
      <Separator character={<span>{` `}</span>} />
      <Link to="/index-blog/">Blog</Link>
      <Separator character={<span>{` `}</span>} />
      <Link to="/index-project/">Projects</Link>
    </SiteLinks>
  </Layout>
)

export default ThirdPage
